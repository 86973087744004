<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <div class="d-flex align-items-center justify-content-between mt-1 mb-2 pb-50">
          <b-link
            class="brand-logo my-0"
            :to="{ name: 'login' }"
          >
            <logo />
            <h2 class="brand-text text-primary ml-1">
              {{ appName }}
            </h2>
          </b-link>
          <locale />
        </div>

        <b-card-text class="mb-2">
          {{ $t('registerTitle') }}
        </b-card-text>

        <validation-observer ref="vo">
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="onSubmitForm"
          >
            <b-form-group :label="$t('Country')">
              <v-select
                v-model="country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :options="countries"
                label="text"
                :reduce="i => i.value"
              >
                <template #no-options>
                  {{ $t('The list is empty') }}
                </template>
              </v-select>
            </b-form-group>
            <b-form-group
              :label="$t('First Name')"
              label-for="first-name"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="first_name"
                rules="required"
              >
                <b-form-input
                  id="first-name"
                  v-model="firstName"
                  :state="errors.length ? false : null"
                  name="first-name"
                  :placeholder="$t('placeholderFirstName')"
                />
                <small class="text-danger">
                  <template v-if="failedRules.required">{{ $t('validationErrorRequiredFirstName') }}</template>
                  <template v-else>{{ errors[0] }}</template>
                </small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('Last Name')"
              label-for="last-name"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="last_name"
                rules="required"
              >
                <b-form-input
                  id="last-name"
                  v-model="lastName"
                  :state="errors.length ? false : null"
                  name="last-name"
                  :placeholder="$t('placeholderLastName')"
                />
                <small class="text-danger">
                  <template v-if="failedRules.required">{{ $t('validationErrorRequiredLastName') }}</template>
                  <template v-else>{{ errors[0] }}</template>
                </small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('Email Address')"
              label-for="email"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="regEmail"
                  :state="errors.length ? false : null"
                  name="register-email"
                  placeholder="john@example.com"
                  :disabled="isLoading"
                />
                <small class="text-danger">
                  <template v-if="failedRules.required">{{ $t('validationErrorRequiredEmail') }}</template>
                  <template v-else-if="failedRules.email">{{ $t('validationErrorEmail') }}</template>
                  <template v-else>{{ errors[0] }}</template>
                </small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('Password')"
              label-for="password"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    :state="errors.length ? false : null"
                    class="form-control-merge"
                    name="register-password"
                    placeholder="············"
                    :disabled="isLoading"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">
                  <template v-if="failedRules.required">{{ $t('validationErrorRequiredPassword') }}</template>
                  <template v-else>{{ errors[0] }}</template>
                </small>
              </validation-provider>
            </b-form-group>
            <b-button
              variant="primary"
              block
              type="submit"
              :disabled="isLoading"
            >
              {{ $t('Create Account') }}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <i18n path="registerMessage">
            <template v-slot:slot>
              <b-link :to="{ name: 'login' }">
                {{ $t('Sign in') }}
              </b-link>
            </template>
          </i18n>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BLink,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters, mapActions } from 'vuex'
import { $themeConfig } from '@themeConfig'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import REGISTER from '@/gql/mutation/user/register.gql'

export default {
  components: {
    Logo,
    BCard,
    BLink,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    Locale,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [
    togglePasswordVisibility,
  ],
  data() {
    return {
      appName: $themeConfig.app.appName,

      country: null,
      firstName: '',
      lastName: '',
      regEmail: '',
      password: '',

      isLoading: false,

      // validation rules
      required,
      email,
    }
  },
  computed: {
    ...mapGetters({
      locale: 'main/locale',
      countries: 'dictionaries/countries',
      countryRegistration: 'main/countryRegistration',
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    this.country = this.countryRegistration || this.countries[0].value
  },
  methods: {
    ...mapActions({
      setCountryRegistration: 'main/setCountryRegistration',
    }),
    onSubmitForm() {
      this.$refs.vo.validate().then(async isValid => {
        if (!isValid) {
          return
        }

        try {
          this.isLoading = true

          await this.$apollo.mutate({
            mutation: REGISTER,
            variables: {
              locale: this.locale,
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.regEmail,
              password: this.password,
              country: this.country,
            },
          })

          if (this.countryRegistration) {
            this.setCountryRegistration(null)
            this.$gtag.event('website_start_trial_signup', {
              value: 1,
            })
          }

          await this.$router.push({ name: 'register-success', params: { email: this.regEmail } })
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Error'),
              text: Array.isArray(error.graphQLErrors[0].message)
                ? error.graphQLErrors[0].message[0]
                : error.graphQLErrors[0].message,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      })
    },
  },
}
</script>

<style lang="sass">
  @import '@core/scss/vue/pages/page-auth.scss'
  @import '@core/scss/vue/libs/vue-select.scss'
</style>
