var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between mt-1 mb-2 pb-50"},[_c('b-link',{staticClass:"brand-logo my-0",attrs:{"to":{ name: 'login' }}},[_c('logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" "+_vm._s(_vm.appName)+" ")])],1),_c('locale')],1),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('registerTitle'))+" ")]),_c('validation-observer',{ref:"vo"},[_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitForm($event)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('Country')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"clearable":false,"options":_vm.countries,"label":"text","reduce":function (i) { return i.value; }},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('The list is empty'))+" ")]},proxy:true}]),model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('First Name'),"label-for":"first-name"}},[_c('validation-provider',{attrs:{"name":"first_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-form-input',{attrs:{"id":"first-name","state":errors.length ? false : null,"name":"first-name","placeholder":_vm.$t('placeholderFirstName')},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('small',{staticClass:"text-danger"},[(failedRules.required)?[_vm._v(_vm._s(_vm.$t('validationErrorRequiredFirstName')))]:[_vm._v(_vm._s(errors[0]))]],2)]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Last Name'),"label-for":"last-name"}},[_c('validation-provider',{attrs:{"name":"last_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-form-input',{attrs:{"id":"last-name","state":errors.length ? false : null,"name":"last-name","placeholder":_vm.$t('placeholderLastName')},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('small',{staticClass:"text-danger"},[(failedRules.required)?[_vm._v(_vm._s(_vm.$t('validationErrorRequiredLastName')))]:[_vm._v(_vm._s(errors[0]))]],2)]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Email Address'),"label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length ? false : null,"name":"register-email","placeholder":"john@example.com","disabled":_vm.isLoading},model:{value:(_vm.regEmail),callback:function ($$v) {_vm.regEmail=$$v},expression:"regEmail"}}),_c('small',{staticClass:"text-danger"},[(failedRules.required)?[_vm._v(_vm._s(_vm.$t('validationErrorRequiredEmail')))]:(failedRules.email)?[_vm._v(_vm._s(_vm.$t('validationErrorEmail')))]:[_vm._v(_vm._s(errors[0]))]],2)]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Password'),"label-for":"password"}},[_c('validation-provider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length ? false : null,"name":"register-password","placeholder":"············","disabled":_vm.isLoading},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[(failedRules.required)?[_vm._v(_vm._s(_vm.$t('validationErrorRequiredPassword')))]:[_vm._v(_vm._s(errors[0]))]],2)]}}])})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":_vm.isLoading}},[_vm._v(" "+_vm._s(_vm.$t('Create Account'))+" ")])],1)],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('i18n',{attrs:{"path":"registerMessage"},scopedSlots:_vm._u([{key:"slot",fn:function(){return [_c('b-link',{attrs:{"to":{ name: 'login' }}},[_vm._v(" "+_vm._s(_vm.$t('Sign in'))+" ")])]},proxy:true}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }